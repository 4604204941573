<template>
  <v-app :style="colorVapp">
    <onebox_admin_toolbar
      :quicksearch="true"
      @loadfile="loadfolder()"
      @callstorage="loadstorage()"
      @closeDrag="removeEvent()"
      @openDrag="addEvent()"
    ></onebox_admin_toolbar>
    <v-content>
      <v-card class="elevation-0">
        <v-divider></v-divider>
        <v-overlay :value="processloader" absolute :color="color.BG" dark>
          <v-progress-circular
            indeterminate
            size="64"
            :color="color.theme"
          ></v-progress-circular>
          <span :style="colorProgress">&nbsp; loading</span>
        </v-overlay>

        <!-- hearder -->
        <v-list nav :color="color.BG">
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">mdi-inbox-arrow-down-outline</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span :style="headerPage"
                  >&nbsp;{{ $t("admin.manageinbox") }}</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <!-- content -->
        <v-card
          class="elevation-0"
          :color="color.BG"
          id="thiscontainerinboxadmin"
        >
          <v-card-text class="pa-0">
            <v-container fluid>
              <v-layout row wrap justify-center fill-height>
                <v-flex
                  lg12
                  xs12
                  class="ma-2 ml-4 mr-4"
                  v-if="loaddataprogress === true"
                >
                  <!-- <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div> -->
                  <v-skeleton-loader
                    class="mx-auto"
                    type="table"
                  ></v-skeleton-loader>
                </v-flex>
                <v-flex lg12 class="ma-4" v-else>
                  <div class="text-right">
                    <v-btn
                      class="mr-5 ma-2"
                      x-small
                      fab
                      :dark="color.darkTheme"
                      :color="color.theme"
                      @click="loadstaff(), (showReload = false)"
                      @mouseover="showReload = true"
                      @mouseleave="showReload = false"
                      ><v-icon small>mdi-reload</v-icon
                      ><v-badge
                        style="z-index:10000;bottom:-45px;left:27px;"
                        :value="showReload"
                        :content="$t('balloon.reload')"
                        left
                        transition="slide-y-transition"
                      ></v-badge
                    ></v-btn>
                  </div>
                  <v-tabs v-model="tab" :color="color.theme">
                    <v-tabs-slider :slider-color="color.theme"></v-tabs-slider>
                    <v-tab :color="color.theme">
                      {{ $t("admin.department") }}
                    </v-tab>
                    <v-tab :color="color.theme">
                      {{ $t("admin.employee") }}
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab">
                    <!-- แผนก -->
                    <v-tab-item>
                      <div v-if="resolutionScreen >= 500">
                        <v-layout row wrap class="pl-3 pr-3">
                          <v-flex lg2>
                            <v-text-field
                              :rules="[rules.selecteddepartment]"
                              class="pt-6"
                              :label="$t('admin.search')"
                              v-model="searchdepartment"
                              clearable
                              outlined
                              dense
                              :color="color.theme"
                            ></v-text-field>
                          </v-flex>
                          <v-flex lg3> </v-flex>
                          <v-flex offset-lg5 lg2 class="pt-6">
                            <v-select
                              v-model="selectpermission"
                              :items="
                                $t('default') === 'en'
                                  ? permissionAll
                                  : permissionAll_th
                              "
                              item-text="name"
                              item-value="status"
                              :label="$t('admin.settingall')"
                              outlined
                              dense
                              :color="color.theme"
                              :item-color="color.theme"
                              @change="setpermissionall(selectpermission)"
                            ></v-select>
                          </v-flex>
                          <v-flex lg12 xs12>
                            <!-- คอม-->
                            <v-data-table
                              :search="searchdepartment"
                              class="elevation-0"
                              :headers="headerdepartment"
                              :items="departments"
                              :hide-default-footer="true"
                              @page-count="pageCountDe = $event"
                              :page.sync="pageDe"
                              v-model="selected"
                              item-key="department_id"
                              :single-select="false"
                              item-selected
                              :show-select="true"
                            >
                              <template
                                v-slot:[`header.department_name`]="{ header }"
                                ><span :style="headerTable">{{
                                  $t(header.text)
                                }}</span></template
                              >
                              <template
                                v-slot:[`header.department_status`]="{ header }"
                                ><span :style="headerTable">{{
                                  $t(header.text)
                                }}</span></template
                              >
                              <template v-slot:item="props">
                                <tr
                                  v-if="
                                    props.item.department_name !==
                                      'all department' &&
                                      props.item.department_name !==
                                        'no_department'
                                  "
                                >
                                  <td class="text-center">
                                    <v-checkbox
                                      v-model="selected"
                                      :value="props.item"
                                      :color="color.theme"
                                    />
                                  </td>
                                  <td width="80%" class="text-center">
                                    {{ props.item.department_name }}
                                  </td>
                                  <td width="20%">
                                    <v-select
                                      style="margin-top:10px; margin-bottom:0px;"
                                      v-model="props.item.permission_inbox"
                                      :items="
                                        $t('default') === 'en'
                                          ? permission
                                          : permission_th
                                      "
                                      item-text="name"
                                      item-value="status"
                                      outlined
                                      dense
                                      :color="color.theme"
                                      :item-color="color.theme"
                                      @change="
                                        setpermissiondepartment(props.item)
                                      "
                                    ></v-select>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                          </v-flex>
                        </v-layout>
                      </div>
                      <!-- หน้าจอโทรศัพท์ -->
                      <div v-else>
                        <v-layout row wrap class="pl-3 pr-3">
                          <v-flex lg12 xs12>
                            <div>
                              <!-- staff -->
                              <v-list two-line class="mb-6 pb-6 elevation-0">
                                <v-list-item
                                  v-for="(item, i) in paginatedDepartment"
                                  :key="i"
                                >
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-text="item.department_name"
                                    ></v-list-item-title>
                                    <!-- <v-list-item-subtitle v-text="item.department_name"></v-list-item-subtitle> -->
                                  </v-list-item-content>
                                  <v-list-item-content>
                                    <v-select
                                      v-model="item.permission_inbox"
                                      style="margin-top:10px; margin-bottom:0px"
                                      :items="
                                        $t('default') === 'en'
                                          ? permission
                                          : permission_th
                                      "
                                      item-text="name"
                                      item-value="status"
                                      outlined
                                      dense
                                      :color="color.theme"
                                      :item-color="color.theme"
                                      @change="setpermissiondepartment(item)"
                                    ></v-select>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list></div></v-flex
                        ></v-layout>
                      </div>
                      <!-- <v-layout row wrap class="pl-3 pr-3" v-else><v-flex lg12 xs12>ชื่อแผนก:</v-flex>
                 <v-flex lg12 xs12>
                 <v-select
                    
                    v-model="selectdepartment"
                    :items="departments"
                    item-value="department_id"
                    item-text="department_name"
                    solo                 
                    label="ชื่อแผนก"
                    required
                    return-object
                ></v-select>
                 </v-flex>
               <v-flex lg12 xs12 >ชื่อพนักงาน:</v-flex>
                 <v-flex lg12 xs12>
                 <v-select
                    v-model="selectstaff"
                    :items="staff"
                    item-text="staff_name_th"
                    item-value="staff_id"
                    solo                  
                    label="ชื่อพนักงาน"
                    required
                    multiple
                    chips
                    return-object                  
                ></v-select>
                 </v-flex>
                 <v-flex lg12 xs12>สถานะ:</v-flex>
                 <v-flex lg12 xs12>
                 <v-select
                    v-model="selectpermission"
                    :items="permission"
                    solo                  
                    label="สถานะ"
                    required
                ></v-select>
                 </v-flex>
                 <v-flex lg12 xs12><v-btn :color="color.theme" :dark="color.darkTheme" @click="setpermissionbussiness()">setting</v-btn></v-flex>
                 </v-layout> -->
                      <!-- <div v-else>
                 <v-layout row wrap class="pl-3 pr-3"  >                 -->
                      <!-- <v-flex offset-lg lg2>
                      <v-select                 
                        v-model="selectset"
                        :items="functionset" 
                        item-value="value"
                        item-text="name"            
                        required
                        :label="$t('admin.selectoption')"
                        outlined
                      ></v-select>
                    </v-flex>
                    <v-flex lg3 class="pl-1" >
                      <v-select
                        v-if="selectset === 'employee'"
                        v-model="filterdepartment"
                        :items="departments"                    
                        item-value="department_id"
                        item-text="department_name"
                        :label="$t('admin.filterdepartment')"
                        outlined                
                        required
                        @change="loaddataprogress = true,loaddepartment()"
                      ></v-select>
                    </v-flex> -->
                      <!-- <v-flex offset-lg5 lg2 >
                        <v-select
                            v-model="selectpermission" 
                            :items="permission"
                            item-text="name"
                            item-value="status"
                            :label="$t('admin.settingall')"
                            outlined
                            dense
                            
                            @change="setpermissionall(selectpermission)"
                        ></v-select>
                      </v-flex> -->
                      <!-- <v-flex lg12  xs12  >
                      <div>      -->

                      <!-- department -->
                      <!-- <v-list two-line class="mb-6 pb-6 elevation-2" >
                      <v-list-item v-for="item in departmentforlist" :key="item.title" >
                        <v-list-item-content >
                          <v-list-item-title  v-text="item.department_name"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action></v-list-item-action>
                      </v-list-item>
                      </v-list> -->
                      <!-- <v-list v-else class="mb-6 pb-6 elevation-2">
                     <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>  -->
                      <!-- </div></v-flex></v-layout>
               </div> -->

                      <br />
                      <v-layout row wrap justify-center>
                        <v-flex xs11 lg11 v-if="departments.length > 0">
                          <v-pagination
                            :total-visible="10"
                            v-model="pageDe"
                            :length="pageCountDe || 0"
                            :color="color.theme"
                            @input="changepage()"
                          ></v-pagination>
                        </v-flex>
                      </v-layout>
                    </v-tab-item>
                    <!-- พนักงาน -->

                    <v-tab-item>
                      <div v-if="resolutionScreen >= 500">
                        <v-layout row wrap class="pl-3 pr-3">
                          <v-flex lg2>
                            <v-text-field
                              :rules="[rules.selected]"
                              class="pt-6"
                              :label="$t('admin.search')"
                              v-model="search"
                              clearable
                              outlined
                              dense
                              :color="color.theme"
                            ></v-text-field>
                          </v-flex>
                          <v-flex lg3> </v-flex>
                          <v-flex offset-lg5 lg2 class="pt-6">
                            <v-select
                              v-model="selectpermission"
                              :items="
                                $t('default') === 'en'
                                  ? permissionAll
                                  : permissionAll_th
                              "
                              item-text="name"
                              item-value="status"
                              :label="$t('admin.settingall')"
                              outlined
                              dense
                              :color="color.theme"
                              :item-color="color.theme"
                              @change="setpermissionall(selectpermission)"
                            ></v-select>
                          </v-flex>
                          <v-flex lg12 xs12>
                            <div>
                              <!-- staff -->
                              <v-data-table
                                v-model="selectedstaff"
                                :headers="
                                  $t('default') === 'th' ? header : header1
                                "
                                :items="staff"
                                item-key="staff_id"
                                :single-select="false"
                                :search="search"
                                class="elevation-0"
                                :show-select="true"
                                item-selected
                                :page.sync="page"
                                :items-per-page="size"
                                :hide-default-footer="true"
                                @page-count="pageCount = $event"
                                :no-data-text="$t('tablefile.empty')"
                              >
                                <template
                                  v-if="$t('default') === 'th'"
                                  v-slot:[`header.staff_name_th`]="{ header }"
                                  ><span :style="headerTable">{{
                                    $t(header.text)
                                  }}</span></template
                                >
                                <template
                                  v-else
                                  v-slot:[`header.staff_name_en`]="{ header }"
                                  ><span :style="headerTable">{{
                                    $t(header.text)
                                  }}</span></template
                                >
                                <template
                                  v-slot:[`header.department_name`]="{ header }"
                                  ><span :style="headerTable">{{
                                    $t(header.text)
                                  }}</span></template
                                >
                                <template
                                  v-slot:[`header.permission_inbox`]="{
                                    header,
                                  }"
                                  ><span :style="headerTable">{{
                                    $t(header.text)
                                  }}</span></template
                                >
                                <template v-slot:item="props">
                                  <tr>
                                    <td class="text-center">
                                      <v-checkbox
                                        v-model="selectedstaff"
                                        :value="props.item"
                                        :color="color.theme"
                                      />
                                    </td>
                                    <td
                                      width="50%"
                                      v-if="$t('default') === 'th'"
                                    >
                                      {{ props.item.staff_name_th }}
                                    </td>
                                    <td width="50%" v-else>
                                      {{ props.item.staff_name_en }}
                                    </td>
                                    <td width="30%" class="text-center">
                                      {{ props.item.department_name }}
                                    </td>
                                    <td width="20%" class="text-center">
                                      <v-select
                                        v-model="props.item.permission_inbox"
                                        style="margin-top:10px; margin-bottom:0px"
                                        :items="
                                          $t('default') === 'en'
                                            ? permission
                                            : permission_th
                                        "
                                        item-text="name"
                                        item-value="status"
                                        outlined
                                        dense
                                        :color="color.theme"
                                        :item-color="color.theme"
                                        @change="
                                          setpermissionstaff(
                                            'S',
                                            props.item.department_name,
                                            props.item.staff_id,
                                            props.item.permission_inbox
                                          )
                                        "
                                      ></v-select>
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                            </div> </v-flex
                        ></v-layout>
                      </div>
                      <!-- หน้าจอโทรศัพท์ -->
                      <div v-else>
                        <v-layout row wrap class="pl-3 pr-3">
                          <v-flex lg12 xs12>
                            <div>
                              <!-- staff -->
                              <v-list two-line class="mb-6 pb-6 elevation-0">
                                <v-list-item
                                  v-for="(item, j) in paginatedData"
                                  :key="j"
                                >
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-text="item.staff_name_th"
                                    ></v-list-item-title>
                                    <!-- <v-list-item-subtitle v-text="item.department_name"></v-list-item-subtitle> -->
                                  </v-list-item-content>
                                  <v-list-item-content>
                                    <v-select
                                      v-model="item.permission_inbox"
                                      style="margin-top:10px; margin-bottom:0px"
                                      :items="
                                        $t('default') === 'en'
                                          ? permission
                                          : permission_th
                                      "
                                      item-text="name"
                                      item-value="status"
                                      outlined
                                      dense
                                      :color="color.theme"
                                      :item-color="color.theme"
                                      @change="
                                        setpermissionstaff(
                                          'S',
                                          item.department_name,
                                          item.staff_id,
                                          item.permission_inbox
                                        )
                                      "
                                    ></v-select>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list></div></v-flex
                        ></v-layout>
                      </div>
                      <br />
                      <v-layout row wrap justify-center>
                        <v-flex xs11 lg11 v-if="staff.length > 0">
                          <v-pagination
                            :total-visible="10"
                            v-model="page"
                            :length="pageCount || 0"
                            :color="color.theme"
                            @input="changepage()"
                          ></v-pagination>
                        </v-flex>
                      </v-layout>
                    </v-tab-item>
                  </v-tabs-items>
                </v-flex> </v-layout></v-container></v-card-text
        ></v-card>
      </v-card>
    </v-content>
  </v-app>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import gbfGenerate from "../globalFunctions/generateAuthorize";
import onebox_admin_toolbar from "../components/layout/layout-admin-toolbar";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  components: {
    onebox_admin_toolbar,
  },
  data: function() {
    return {
      showReload: false,
      size: 20,
      page: 1,
      sizeDe: 20,
      pageDe: 1,
      selectedstaff: [],
      departmentforlist: [],
      selected: [],
      selectset: "employee",
      databuffer: [],
      tab: null,
      filterdepartment: "",
      selectpermission: " ",
      showadminMenu: false,
      search: "",
      searchdepartment: "",
      loaddataprogress: true,
      departments: [],
      staff: [],
      permission: [
        { name: "Allow", status: "AL" },
        { name: "Denined", status: "DE" },
        { name: "Download Only", status: "DL" },
        { name: "Download/Delete", status: "AD" },
      ],
      permission_th: [
        { name: "สิทธิ์ดูเท่านั้น", status: "AL" },
        { name: "ไม่มีสิทธิ์เข้าใช้งาน", status: "DE" },
        { name: "สิทธิ์ดาวน์โหลดเท่านั้น", status: "DL" },
        { name: "สิทธิ์ดาวน์โหลด/ลบ", status: "AD" },
      ],
      permissionAll: [
        { name: "Allow", status: "AL" },
        { name: "Denined", status: "DE" },
        { name: "Download Only", status: "DL" },
        { name: "Download/Delete", status: "AD" },
        { name: "Default", status: "reset" },
      ],
      permissionAll_th: [
        { name: "สิทธิ์ดูเท่านั้น", status: "AL" },
        { name: "ไม่มีสิทธิ์เข้าใช้งาน", status: "DE" },
        { name: "สิทธิ์ดาวน์โหลดเท่านั้น", status: "DL" },
        { name: "สิทธิ์ดาวน์โหลด/ลบ", status: "AD" },
        { name: "ค่าเริ่มต้น", status: "reset" },
      ],
      header: [
        {
          text: "admin.name",
          align: "",
          value: "staff_name_th",
          width: "50%",
          sortable: false,
        },
        {
          text: "admin.department",
          align: "center",
          value: "department_name",
          width: "30%",
          sortable: false,
        },
        {
          text: "admin.option",
          align: "center",
          value: "permission_inbox",
          width: "20%",
          sortable: false,
        },
      ],
      header1: [
        {
          text: "admin.name",
          align: "",
          value: "staff_name_en",
          width: "50%",
          sortable: false,
        },
        {
          text: "admin.department",
          align: "center",
          value: "department_name",
          width: "30%",
          sortable: false,
        },
        {
          text: "admin.option",
          align: "center",
          value: "permission_inbox",
          width: "20%",
          sortable: false,
        },
      ],
      headerdepartment: [
        {
          text: "admin.department",
          align: "center",
          value: "department_name",
          width: "80%",
          sortable: false,
        },
        {
          text: "admin.option",
          align: "center",
          value: "department_status",
          width: "20%",
          sortable: false,
        },
      ],
      rules: {
        selected: (value) => (this.selectedstaff = []),
        selecteddepartment: (value) => (this.selected = []),
      },
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "service",
      "color",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    headerPage() {
      // console.log(this.color.theme);
      return (
        "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;"
      );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    pageCount: {
      get() {
        // Old
        let l = this.staff.length;

        // New api
        // let l = this.fileCount;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    pageCountDe: {
      get() {
        // Old
        let l = this.departments.length;

        // New api
        // let l = this.fileCount;
        let s = this.sizeDe;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.staff.slice(start, start + this.size);
    },
    paginatedDepartment() {
      const start = (this.pageDe - 1) * this.sizeDe;
      const end = start + this.sizeDe;
      return this.departments.slice(start, start + this.sizeDe);
    },
  },
  methods: {
    changepage() {
      this.loaddataprogress = true;
      setTimeout(() => {
        this.loaddataprogress = false;
      }, 1000);
    },
    async loaddepartment() {
      this.departments = [];
      this.departmentforlist = [];
      this.staff = [];
      let depart2 = [];

      var payload = {
        business_id: this.dataAccountActive.business_info.business_id,
        account_id: this.dataAccountId,
        accesstoken: this.dataAccesstoken,
        api_type: "setting_permission_inbox",
        search: ""
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_MANAGE_ACCOUNT +
            "/api/v2/select_account_in_business",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("test inbox", response.data);
            for (let i = 0; i < response.data.result.length; i++) {
              let bufferstaff = {};
              bufferstaff["staff_id"] = response.data.result[i]["account_id"];
              bufferstaff["staff_name_en"] =
                response.data.result[i]["account_title_eng"] +
                " " +
                response.data.result[i]["first_name_eng"];
                // +
                // " " +
                // response.data.result[i]["last_name_eng"];
              bufferstaff["staff_name_th"] =
                response.data.result[i]["account_title_th"] +
                " " +
                response.data.result[i]["first_name_th"]; 
                // +
                // " " +
                // response.data.result[i]["last_name_th"];
              bufferstaff["permission_inbox"] =
                response.data.result[i]["permission_inbox"];
              bufferstaff["permission_outbox"] =
                response.data.result[i]["permission_outbox"];
              bufferstaff["department_name"] =
                response.data.result[i]["department_name"];

              this.staff.push(bufferstaff);
            }
            console.log(this.staff);
            for (let i = 0; i < response.data.department.length; i++) {
              let bufferdepartment = {};
              bufferdepartment["department_id"] =
                response.data.department[i]["department_id"];
              bufferdepartment["department_name"] =
                response.data.department[i]["department_name"];
              bufferdepartment["my_folder_id"] =
                response.data.department[i]["account_title_th"];
              bufferdepartment["permission_inbox"] =
                response.data.department[i]["permission_inbox"];
              bufferdepartment["permission_outbox"] =
                response.data.department[i]["permission_outbox"];

              this.departments.push(bufferdepartment);
            }
          } else {
            Toast.fire({
              icon: "error",
              title:
                response.data.errorCode + ": " + response.data.errorMessage,
            });
          }

          console.log(this.departments);

          this.loaddataprogress = false;
        })
        .catch((error) => {
          this.loaddataprogress = false;
          console.log(error);
          Toast.fire({
            icon: "error",
            title: error,
          });
        });
    },
    async loadstaff() {
      this.loaddataprogress = true;
      this.departments = [];
      this.departmentforlist = [];
      this.staff = [];
      let depart2 = [];

      var payload = {
        business_id: this.dataAccountActive.business_info.business_id,
        account_id: this.dataAccountId,
        accesstoken: this.dataAccesstoken,
        api_type: "setting_permission_inbox",
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_MANAGE_ACCOUNT +
            "/api/update_account_in_business",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("test inbox", response.data);
            for (let i = 0; i < response.data.result.length; i++) {
              let bufferstaff = {};
              bufferstaff["staff_id"] = response.data.result[i]["account_id"];
              bufferstaff["staff_name_en"] =
                response.data.result[i]["account_title_eng"] +
                " " +
                response.data.result[i]["first_name_eng"]; 
                // +
                // " " +
                // response.data.result[i]["last_name_eng"];
              bufferstaff["staff_name_th"] =
                response.data.result[i]["account_title_th"] +
                " " +
                response.data.result[i]["first_name_th"];
                //  +
                // " " +
                // response.data.result[i]["last_name_th"];
              bufferstaff["permission_inbox"] =
                response.data.result[i]["permission_inbox"];
              bufferstaff["permission_outbox"] =
                response.data.result[i]["permission_outbox"];
              bufferstaff["department_name"] =
                response.data.result[i]["department_name"];

              this.staff.push(bufferstaff);
            }
            console.log(this.staff);
            for (let i = 0; i < response.data.department.length; i++) {
              let bufferdepartment = {};
              bufferdepartment["department_id"] =
                response.data.department[i]["department_id"];
              bufferdepartment["department_name"] =
                response.data.department[i]["department_name"];
              bufferdepartment["my_folder_id"] =
                response.data.department[i]["account_title_th"];
              bufferdepartment["permission_inbox"] =
                response.data.department[i]["permission_inbox"];
              bufferdepartment["permission_outbox"] =
                response.data.department[i]["permission_outbox"];

              this.departments.push(bufferdepartment);
            }
          } else {
            Toast.fire({
              icon: "error",
              title:
                response.data.errorCode + ": " + response.data.errorMessage,
            });
          }

          console.log(this.departments);

          this.loaddataprogress = false;
        })
        .catch((error) => {
          this.loaddataprogress = false;
          console.log(error);
          Toast.fire({
            icon: "error",
            title: error,
          });
        });
    },

    setpermissionstaff(status, department, staff, permission) {
      let account_id_update = [];
      let department_update = [];
      if (status === "S") {
        department_update.push("");
        account_id_update.push(staff);

        var payload = {
          default_inbox: "",
          default_outbox: "",
          department_inbox: [""],
          department_outbox: [""],
          account_id_admin: this.dataAccountId,
          business_id: this.dataAccountActive.business_info.business_id,
          account_id_inbox: account_id_update,
          account_id_outbox: [""],
          status_permission: permission,
        };
      }
      console.log(payload);
      this.sendtoapipermissioninbox(payload);
    },
    setpermissiondepartment(parameter) {
      let account_id_update = [];
      let department_id_update = [];
      department_id_update.push(parameter.department_id);
      for (let i = 0; i < this.staff.length; i++) {
        if (this.staff[i].department_id === parameter.department_id) {
          account_id_update.push(this.staff[i].staff_id);
        }
      }
      console.log("parameter.permission_inbox", parameter.permission_inbox);
      var payload = {
        default_inbox: "",
        default_outbox: "",
        department_inbox: department_id_update,
        department_outbox: [""],
        account_id_admin: this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id,
        account_id_inbox: [""],
        account_id_outbox: [""],
        status_permission: parameter.permission_inbox,
      };
      console.log(payload);
      this.sendtoapipermissioninbox(payload);
    },
    setpermissionall(permission) {
      console.log("1", this.selected);
      console.log("2", this.selectedstaff);
      let account_id_update = [];
      let department_id_update = [];

      if (this.selected.length === 0 && this.selectedstaff.length === 0) {
        Toast.fire({
          icon: "error",
          title: "ยังไม่ได้เลือก",
        });
        // this.selectpermission = " ";
        this.selected = [];
        this.selectedstaff = [];
      } else {
        if (this.selectedstaff.length === 0) {
          account_id_update = [""];
          //console.log("a");
        } else {
          for (let i = 0; i < this.selectedstaff.length; i++) {
            //all staff
            account_id_update.push(this.selectedstaff[i].staff_id);
          }
          console.log("ddd", account_id_update);
        }
        if (this.selected.length === 0) {
          department_id_update = [""];
        } else {
          for (let i = 0; i < this.selected.length; i++) {
            //all department
            department_id_update.push(this.selected[i].department_id);
          }
          console.log("ddd", department_id_update);
        }

        var payload = {
          default_inbox: "",
          default_outbox: "",
          department_inbox: department_id_update,
          department_outbox: [""],
          account_id_admin: this.dataAccountId,
          business_id: this.dataAccountActive.business_info.business_id,
          account_id_inbox: account_id_update,
          account_id_outbox: [""],
          status_permission: permission,
        };
        //ฝฝthis.selectpermission = " ";
        this.selected = [];
        this.selectedstaff = [];
        console.log("tt", payload);
        this.sendtoapipermissioninbox(payload);
      }
    },
    async sendtoapipermissioninbox(payload) {
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_API +
            "/api/v2/set_business_permission",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          console.log("respone", response);
          this.loaddepartment();
          if (response.data.status === "OK") {
            // this.selectpermission = " ";
            console.log(this.selectpermission);
            Toast.fire({
              icon: "success",
              title: "อัปเดตสำเร็จ",
            });
            //Update permission inbox outbox (มัยแก้ตรงนี้ครับ)
            let payloadStore = {
              type_permission: payload.status_permission,
              type_box: 'inbox'
             }
            this.$store.dispatch("updatepermissioninoutbox_business_manage", payloadStore)
          } else {
            console.log(this.selectpermission);
            // this.selectpermission = " ";
            Toast.fire({
              icon: "error",
              title:
                response.data.errorCode + ": " + response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
          if (this.selected.length === 0 || this.selectedstaff === 0) {
            console.log(this.selectpermission);
            // this.selectpermission = " ";
            Toast.fire({
              icon: "error",
              title: "ยังไม่ได้เลือก",
            });
          } else {
            console.log(this.selectpermission);
            // this.selectpermission = " ";
            this.selected = [];
            this.selectedstaff = [];
            Toast.fire({
              icon: "error",
              title: error,
            });
          }
        });
    },
  },
  mounted() {
    this.loaddepartment();
    console.log("selectpermission", this.selectpermission);
  },
};
</script>
<style>
#thiscontainerinboxadmin {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
.v-messages {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-size: 12px;
  min-height: 0px;
  min-width: 1px;
  position: relative;
}
.v-text-field__details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  max-width: 100%;
  min-height: 0px;
  overflow: hidden;
}
.v-application .primary--text {
  color: #abaeb1 !important;
  caret-color: #abaeb1 !important;
}
</style>
